console.log( 'Home page!' );

//change these to be in different files, keep them here for now to maintain functionality

ThemeJS.Responsive_menu = (function ( $ ){

    jQuery(document).ready(function(){
        globalHelper.init();
        header.init();	
        smoothScroll.init();
        sidenav.init();
    });
    
    var globalHelper = {
        init: function(){
            this.detectDevice();
        },
        detectDevice: function () {
            //Check for ipad/iphone devices
    
            if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
              jQuery("html, body").addClass("ipad");
            }
            else if (/Edge/.test(navigator.userAgent) && !window.MSStream) {
              jQuery("html, body").addClass("edge");
            }
        },
        //Lock the page from scrolling (used for hamburger open state)
        scrollLock: function(){
            if (!jQuery('body').attr('data-pos')) {
                jQuery('body').attr('data-pos', jQuery(window).scrollTop());
            }
            jQuery("body, html").addClass("scroll-lock");
        },
        //Unlock page scrolling
        scrollUnlock: function(){
            jQuery("body, html").removeClass("scroll-lock");
            jQuery(window).scrollTop(jQuery('body').attr('data-pos'));
            jQuery('body').removeAttr('data-pos');
        }
    }
    
    var header = {
        header: jQuery("header"),
        hamburger: jQuery(".header--hamburger"),
        subnavExpand: jQuery(".header--expand"),
    
        init: function () {        
            header.initMobileNav();
        },
    
        initMobileNav: function () {
    
            //Check for expanded state in the nav and set the correct expand icon
            jQuery(".header--subnav").each(function(){
                if(jQuery(this).hasClass("expanded")){
                  jQuery(this).parent().find(".header--expand").html("&minus;");
                }
                else{
                  jQuery(this).parent().find(".header--expand").html("+"); 
                }
            });
    
            jQuery(window).resize(function(){
              jQuery("body").removeClass('menu-open');
              globalHelper.scrollUnlock();
            })
    
            //Toggle hamburger visible
            header.hamburger.on('click keypress', function (e) {
                //console.log("ASDF");
                if (e.keyCode === 13 || e.keyCode === 32 || e.type === 'click') {
                    e.preventDefault();
                    if (jQuery("body").hasClass('menu-open')){
                      jQuery("body").removeClass('menu-open');
                      globalHelper.scrollUnlock();
                    }
                    else {
                      jQuery("body").addClass('menu-open');
                      globalHelper.scrollLock();
                    }
                }
            });
    
            //Toggle subnav visible
            header.subnavExpand.on('click keypress', function (e) {
                if (e.keyCode === 13 || e.keyCode === 32 || e.type === 'click') {
                    e.preventDefault();
                    var subnav = jQuery(this).parent().find(".header--subnav");
                    if (jQuery(this).parent().find(".header--subnav").hasClass("expanded")){
                      subnav.removeClass("expanded");
                      jQuery(this).html("+");                  
                    }
                    else {
                      subnav.addClass("expanded");
                      jQuery(this).html("&minus;");
                    }
                }
            });
        }
    };
    
    var smoothScroll = {
        init: function () {
            jQuery(".smooth-scroll").on("click touchstart", function (e) {
                e.preventDefault();
    
                smoothScroll.scrollTo(smoothScroll.getOffset(jQuery(e.target).closest("a").attr("href")), 500);
            });
        },
        scrollTo: function (offset, time, callback) {
            jQuery('html body, body, html').stop(true).animate({
                scrollTop: offset
            }, time, function () {
                if (typeof (callback) == "function")
                    callback();
            });
        },
        getOffset: function(e){
            //Finds the position on the page of the element being scrolled to
            return jQuery(e).position().top;
        }
    };
    
    var sidenav = {
        el: jQuery(".sidenav nav"),
        init: function(){
            if (sidenav.el.length > 0){
                jQuery(window).on("scroll resize", function(e){
                    sidenav.setPos();
                });
    
                sidenav.setPos();
            }
        },
        setPos: function(){
            var pos = jQuery(window).scrollTop() - parseInt(jQuery("header").outerHeight());
    
            if (pos > 0){
                sidenav.el.css({
                    "top": 0,
                    "position": "fixed",
                    "width": sidenav.el.parent().outerWidth()
                });	
            }
            else{
                sidenav.el.removeAttr("style");	
            }
        }
    }
    
})();




